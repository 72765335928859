import { styled } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import { Form } from "formik";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import * as z from "zod";
import { COMMUNE_NO_GROUP_ID } from "~/common/organization/group";
import { organizationUserUpdateDtoSchema } from "~/common/organization/user/dtos";

import { OrganizationGroupRepository } from "../../../services/api/repositories/OrganizationGroupRepository";
import { ToastVariants } from "../../ui-atoms/components/Toast";
import { showToast } from "../../ui-atoms/services/showToast";
import { Button, ButtonVariants } from "../../ui-form/components/Button/Button";
import {
	FormElementContainer,
	FormElementWrapper,
	FormInputsContainer,
	LabelStyled,
	RequiredStyled,
} from "../../ui-form/components/Formik/FormElementWrapper";
import { Input } from "../../ui-form/components/Input";
import { Select, SelectOption } from "../../ui-form/components/Select";
import { Switch } from "../../ui-form/components/Switch";
import { FormLayout } from "../../ui-layout/components/FormLayout";
import { UserProps } from "../../ui-layout/components/Modal/AccountFormModal";
import { ConfirmModalButton } from "../../ui-layout/components/Modal/ConfirmModal";
import { useModal } from "../../ui-layout/components/Modal/ModalProvider";

interface EditUserFormProps {
	organizationId: number;
	setUserStatus: (status: boolean) => Promise<void>;
	user: UserProps;
}

export const EditUserForm = ({
	organizationId,
	setUserStatus,
	user,
}: EditUserFormProps) => {
	const { t } = useTranslation();

	const [isEnabled, setIsEnabled] = useState(user.enabled);

	const { data: groups, error } = useQuery({
		...OrganizationGroupRepository.findAndCount({}, organizationId),
	});
	if (error) {
		showToast({
			message: t("pages.users.add-user.errors.group_error"),
			title: t("common.api.errors.generic.message"),
			variant: ToastVariants.ERROR,
		});
	}

	const groupOptions =
		groups?.data.map<SelectOption>(({ _id, name }) => ({
			label: `${t("entities.user.utility-service")} ${name}`,
			value: _id,
		})) || [];
	// We manually insert the "Commune" option in the dropdown, which won't
	// create any group for the user
	groupOptions.unshift({
		label: t("pages.users.add-user.select-option-commune"),
		value: COMMUNE_NO_GROUP_ID as number,
	});

	const modal = useModal();

	useEffect(() => {
		setIsEnabled(user.enabled);
	}, [user.enabled]);

	return (
		<Form data-testid="edit-user-form">
			<FormLayout>
				<FormElementWrapper<FormValues>
					label={t("entities.user.last-name")}
					names={["data.name.last"]}
					required
				>
					<Input />
				</FormElementWrapper>

				<FormElementWrapper<FormValues>
					label={t("entities.user.first-name")}
					names={["data.name.first"]}
					required
				>
					<Input />
				</FormElementWrapper>

				<FormElementContainer>
					<LabelStyled disabled htmlFor={t("entities.user.email")}>
						{t("entities.user.email")}
						<RequiredStyled>*</RequiredStyled>
					</LabelStyled>
					<FormInputsContainer layout="vertical" wrapElements>
						<Input disabled value={user.email} />
					</FormInputsContainer>
				</FormElementContainer>
				<FormElementWrapper<FormValues>
					label={t("entities.user.utility")}
					names={["data.groupId"]}
					required
				>
					<Select options={groupOptions} />
				</FormElementWrapper>

				<FormElementWrapper<FormValues> names={["data.isAdmin"]}>
					<Switch label={t("entities.user.admin")} />
				</FormElementWrapper>

				<DisableButtonContainer>
					{isEnabled ? (
						<Button
							label={t("pages.user-edit.form.deactivate")}
							onClick={() => {
								modal.openModal("confirm", {
									buttons: [
										ConfirmModalButton.BUTTON_NO,
										ConfirmModalButton.BUTTON_DELETE,
									],
									message: t("pages.user-edit.modal.content"),
									onClick: async btn => {
										if (
											btn ===
											ConfirmModalButton.BUTTON_DELETE
										) {
											setIsEnabled(false);
											await setUserStatus(false);
											modal.closeModal();
										}
										modal.closeModal();
									},
									onClose: () => modal.closeModal(),
									title: t("pages.user-edit.modal.title"),
								});
							}}
							variant={ButtonVariants.DANGER_SECONDARY}
						/>
					) : (
						<Button
							label={t("pages.user-edit.form.activate")}
							onClick={() => {
								setIsEnabled(true);
								void setUserStatus(true);
								modal.closeModal();
							}}
							variant={ButtonVariants.SECONDARY}
						/>
					)}
				</DisableButtonContainer>
			</FormLayout>
		</Form>
	);
};

const DisableButtonContainer = styled("div")`
	margin-top: 20px;
	width: 100%;
`;

const validationSchema = organizationUserUpdateDtoSchema;
type FormValues = z.infer<typeof validationSchema>;

export { validationSchema as editUserFormValuesSchema };
export type { FormValues as EditUserFormValues };
