import {
	Select as SelectJoy,
	selectClasses,
	Option,
	optionClasses,
	Theme,
	styled,
	type SelectOption as SelectOptionJoy,
} from "@mui/joy";
import { useTranslation } from "next-i18next";
import { ChangeEvent, ReactNode } from "react";

import { UI_INPUT_CSS_VARS } from "./style";
import { Icon, IconName } from "../../ui-atoms/components/Icons/Icon";
import { textSmall } from "../../ui-layout/styles/textStyles";

export const SELECT_EMPTY_LABEL = "--";

type ValueType = boolean | number | string;

/**
 * Select option
 *
 * @param label The text to display in the select
 * @param value The value to return when the option is selected
 */
export interface SelectOption {
	label: ReactNode;
	value: ValueType;
}

interface SelectProps {
	defaultValue?: ValueType;
	disabled?: boolean;
	error?: boolean;
	multiple?: boolean;
	name?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	options: SelectOption[];
	placeholder?: string;
	value?: ValueType;
}

/**
 * Select component
 *
 * @param props The props
 * @param props.error Whether the select has an error
 * @param props.multiple Whether the select allows multiple selections
 * @param props.name The form name of the select
 * @param props.onChange The function to call when the select value changes
 * @param props.options The options to display in the select
 * @param props.value The value of the select
 * @returns The component
 */
export const Select = ({
	error,
	multiple,
	name,
	onChange,
	options,
	value,
	...rest
}: SelectProps): JSX.Element => {
	const { t } = useTranslation();
	const optionElements = options.map(option => (
		<OptionStyled key={option.value?.toString()} value={option.value}>
			{option.label}
		</OptionStyled>
	));

	const onChangeHandler = (_: unknown, newValue: number | string | null) => {
		// We skip null because it's the initial value of the select, and we don't
		// want the field to be touched from the start
		if (!onChange || newValue === null) {
			return;
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we don't need the extra props from the onChange event
		onChange({
			target: {
				name,
				value: newValue,
			},
		} as ChangeEvent<HTMLInputElement>);
	};

	// We use a custom renderer so that we can display the placeholder text when no option is selected
	const renderValue = (
		// eslint-disable-next-line @typescript-eslint/ban-types -- we don't know the type of the options
		options: SelectOptionJoy<{}> | Array<SelectOptionJoy<{}>> | null,
	) => {
		const placeholderText = t("common.form.select.placeholder");
		if (!options) {
			return placeholderText;
		} else if (Array.isArray(options)) {
			return 0 === options.length ? placeholderText : options.join(", ");
		} else {
			return options.label === SELECT_EMPTY_LABEL
				? placeholderText
				: options.label;
		}
	};

	return (
		<SelectStyled
			error={error ? "true" : null} // need to cast it as string to prevent Joy from complaining
			id={name}
			indicator={<Icon name={IconName.chevronDown} />}
			multiple={multiple}
			name={name}
			onChange={onChangeHandler as never}
			renderValue={renderValue}
			slotProps={{ listbox: { sx: getListBoxStyle } }}
			value={value}
			{...rest}
		>
			{optionElements}
		</SelectStyled>
	);
};

const SelectStyled = styled(SelectJoy)<{ error: string | null }>`
	background-color: var(
		${UI_INPUT_CSS_VARS.bgColor},
		${({ theme }) => theme.vars.palette.grey[1]}
	);
	border: 1px solid
		${({ error, theme }) =>
			error ? theme.vars.palette.red[1] : theme.vars.palette.grey[2]};
	border-radius: 4px;
	box-shadow: none;
	color: ${({ theme, value }) =>
		value || value === 0
			? theme.vars.palette.grey.text
			: theme.vars.palette.grey[3]};
	${textSmall}
	height: 32px;
	min-height: 32px;
	padding: 0px 12px;

	&:hover {
		background: ${({ theme }) => theme.vars.palette.grey[1]};
	}

	&.${selectClasses.disabled} {
		background: ${({ theme }) => theme.vars.palette.grey[2]};
		color: ${({ theme }) => theme.vars.palette.grey[4]};
	}

	&.${selectClasses.disabled} .${selectClasses.button} {
		cursor: not-allowed !important;
		pointer-events: all !important;
	}

	&.${selectClasses.indicator} {
		color: ${({ theme }) => theme.vars.palette.grey[4]};
	}
`;

const OptionStyled = styled(Option)`
	border-bottom: 1px solid ${({ theme }) => theme.vars.palette.grey[2]};
	color: ${({ theme }) => theme.vars.palette.grey[4]};
	${textSmall}

	&.${optionClasses.selected} {
		background: none;
	}

	&.${optionClasses.root}:hover {
		background: none;
		color: ${({ theme }) => theme.vars.palette.grey[4]};
	},
`;

/**
 * getListBoxStyle
 *
 * @param theme the Joy theme
 * @returns The style for the listbox
 */
export function getListBoxStyle(theme: Theme): Record<string, unknown> {
	return {
		background: theme.vars.palette.white.white,
		border: `1px solid ${theme.vars.palette.grey[2]}`,
		borderRadius: "4px",
		padding: 0,
	};
}
