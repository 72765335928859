import * as z from "zod";

import { BaseModelOmit, createSchema, DenormalizeError } from "./base";
import { SchemaValidationOptions } from "../input.validation-schema";

/** The type of this input */
export const TYPE = "date";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE).extend({
	maxDate: z
		.number()
		.nullable()
		.default(null)
		.describe("Maximum accepted date (JS/UNIX timestamp)"),
	minDate: z
		.number()
		.nullable()
		.default(null)
		.describe("Minimum accepted date (JS/UNIX timestamp)"),
	multiple: z
		.boolean()
		.default(false)
		.describe("Multiple date can be selected"),

	// TODO ?
	// relative: z
	// 	.boolean()
	// 	.default(false)
	// 	.describe(
	// 		"When set, the max & min are relative to the current date.<br>" +
	// 			"  So the values become offset from the current timestamp.<br>" +
	// 			"  Ex: a `max` of 1000 => the max date is `current() + 1000`",
	// 	),
});

/** Input of type: date */
export type Model = z.infer<typeof schema>;

/**
 * Create a zod validation schema depends on given input
 *
 * @param input rules validation
 * @param options create input configuration
 * @returns schema generated by given input
 */
export const createInputSchema = (
	input: BaseModelOmit<Model>,
	options: SchemaValidationOptions = { skipRequired: false },
) => {
	let dateSchema = z.date();
	if (input.maxDate) {
		dateSchema = dateSchema.max(new Date(input.maxDate));
	}
	if (input.minDate) {
		dateSchema = dateSchema.min(new Date(input.minDate));
	}

	const schema = input.multiple
		? z.array(dateSchema)
		: z.array(dateSchema).max(1);
	if (!input.required || options.skipRequired) {
		return schema.optional();
	}
	return schema.nonempty();
};

/**
 * Normalize input to an array of date
 *
 * @param value value to normalize
 * @returns normalized value
 * @throws {DenormalizeError} if the value is not a valid date
 */
export function denormalize(value: string) {
	try {
		const raw: unknown = JSON.parse(value);

		if (raw === null) {
			return null;
		}
		if (!Array.isArray(raw)) {
			throw new Error();
		}

		return raw.map((item: string) => {
			const date = new Date(item);

			// Check if date is valid or throw error
			if (Number.isNaN(date.getTime())) {
				throw new Error();
			}
			return date;
		});
	} catch {
		// We don't need to catch specific errors, we can mutualize it into a single error
		throw new DenormalizeError(
			`Cannot normalize "${value}" to a array. Expected a string array like "['1', '2']".`,
		);
	}
}
