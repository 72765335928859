import { Model } from "@appti/core";
import * as z from "zod";

import { applicationInstanceStepSchema } from "./instance.step";

/** Validation schema for {@link ApplicationInstanceModel} */
export const applicationInstanceModelSchema = Model.schema.extend({
	fkVersion: Model.schema.shape._id.describe(
		// FIXME : Why applicationVersionModelSchema throw an error ?
		"FK of Version this application has been created from",
	),
	step: applicationInstanceStepSchema.describe(
		"The current step of an instance",
	),

	organizerEmail: z.string().email().describe("Email of a organizer"),
	organizerName: z.string().describe("Organizer name of the application"),
	title: z.string().min(3).describe("Title given to the application"),
});
/** An application is an instance (a request made by a resident) */
export type ApplicationInstanceModel = z.infer<
	typeof applicationInstanceModelSchema
>;
