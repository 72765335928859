import {
	isAdminInOrganization,
	isModeratorInOrganization,
	isSecretaryInOrganization,
} from "./with-organization";
import { ApplicationInstanceModel } from "../../../../application/instance";
import { GroupCheckModel } from "../../../../application/instance/group-check/group-check.model";
import { GroupPermissionModel } from "../../../../application/version/group-permission/group-permission.model";
import { UserExtendedDto } from "../../../../user/dtos/user.extended.dto";
import { GroupModel } from "../../../group";

/** CAN EDIT INPUTS */

/**
 * Check if user can edit an application as an employee
 *
 * @param instance The application instance
 * @param orgId The application organizationId
 * @param user The current user
 * @returns if the user can edit the application
 */
export function canEditAsEmployee(
	instance: ApplicationInstanceModel,
	orgId: number,
	user: UserExtendedDto,
) {
	const { roles } = user;

	return (
		(isAdminInOrganization(roles, orgId) ||
			isSecretaryInOrganization(roles, orgId)) &&
		instance.step !== "done" &&
		instance.step !== "cancelled"
	);
}

/**
 * Check if user can edit an application as an organizer
 *
 * @param instance The application instance
 * @param isPublicRoute If we are on a public page (aka organizer view)
 * @returns if the user can edit the application
 */
export function canEditAsOrganizer(
	instance: ApplicationInstanceModel,
	isPublicRoute: boolean,
) {
	return isPublicRoute && instance.step === "draft";
}

/**
 * Check if group can edit an application element
 *
 * @param user The current user
 * @param sectionId The sectionId to check
 * @param permissions The permissions for the application
 * @returns if the user can edit the application section
 */
export function canEditAsGroup(
	user: UserExtendedDto,
	sectionId: number,
	permissions: GroupPermissionModel[],
) {
	const { groups } = user;

	return groupHasPermissionToEditSection(permissions, sectionId, groups);
}

/** CAN VALIDATE DATA / STEPS */

/**
 * Check if user can validate an application as a group
 *
 * @param instance The application instance
 * @param user The current user
 * @param permissions The permissions for the application
 * @param groupChecks The groupChecks for the application
 * @returns if the user can validate the application
 */
export function canValidateApplicationAsGroup(
	instance: ApplicationInstanceModel,
	user: UserExtendedDto,
	permissions: GroupPermissionModel[],
	groupChecks: GroupCheckModel[],
) {
	const { groups } = user;

	const groupIds = groups.map(group => group._id);
	const groupExistsInInstancePermissions = permissions.some(
		({ fkGroup, step }) =>
			groupIds.includes(fkGroup) && step === instance.step,
	);

	return (
		groupExistsInInstancePermissions &&
		// TODO: why lock when already validated?
		!groupHasValidated(groupChecks, instance, groups)
	);
}

function groupHasPermissionToEditSection(
	permissions: GroupPermissionModel[],
	elementId: number,
	groups: GroupModel[],
) {
	return permissions.some(
		permission =>
			permission.fkElement === elementId &&
			groups.map(group => group._id).includes(permission.fkGroup),
	);
}

function groupHasValidated(
	groupChecks: GroupCheckModel[],
	instance: ApplicationInstanceModel,
	groups: GroupModel[],
) {
	const groupIds = groups.map(group => group._id);

	return groupChecks.some(
		groupCheck =>
			groupCheck.step === instance.step &&
			groupCheck.fkApplication === instance._id &&
			groupIds.includes(groupCheck.fkGroup),
	);
}

/**
 * Check if user can change an application step
 *
 * @param orgId The application organizationId
 * @param user The current user
 * @returns if the user can change the application step
 */
export function canChangeApplicationSteps(
	orgId: number,
	user: UserExtendedDto,
) {
	const { roles } = user;

	return (
		isAdminInOrganization(roles, orgId) ||
		isModeratorInOrganization(roles, orgId)
	);
}

/** SHOW BUTTONS OR NOT */

/**
 * Check if we display the send draft application button
 *
 * @param instance The application instance
 * @param user The current user
 * @param isPublicRoute If we are on a public page (aka organizer view)
 * @returns if we display the button
 */
export function showSendApplicationButton(
	instance: ApplicationInstanceModel,
	user: UserExtendedDto | null,
	isPublicRoute: boolean,
) {
	return !user && isPublicRoute && instance.step === "draft";
}

/**
 * Check if we display the validate application button for organizer
 *
 * @param instance The application instance
 * @param user The current user
 * @param isPublicRoute If we are on a public page (aka organizer view)
 * @returns if we display the button
 */
export function showValidateAsOrganizerButton(
	instance: ApplicationInstanceModel,
	user: UserExtendedDto | null,
	isPublicRoute: boolean,
) {
	return (
		!user && isPublicRoute && instance.step === "pending_client_validation"
	);
}

/**
 * Check if we display the validate-as-group button
 *
 * @param instance The application instance
 * @param user The current user
 * @param permissions The permissions for the application
 * @returns if we display the button
 */
export function showValidateAsGroupButton(
	instance: ApplicationInstanceModel,
	user: UserExtendedDto,
	permissions: GroupPermissionModel[],
) {
	const { step } = instance;
	const { groups } = user;

	const groupExistsInInstancePermissions = permissions.some(permission =>
		groups.map(group => group._id).includes(permission.fkGroup),
	);

	return (
		groupExistsInInstancePermissions &&
		(step === "services_validation" || step === "invoice_preparation")
	);
}
