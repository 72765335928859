import * as z from "zod";

/** Validation schema for {@link ApplicationElementVisibility} */
export const applicationElementVisibilitySchema = z
	.enum([
		"public",
		// Only for users
		"internal",
		// The content can be read (only) after the creation (and not draft)
		"after_create",
	])
	.describe(
		"Visibility of an element (for the resident).<br>" +
			"Known users always see all template",
	);
/**
 * Visibility of an element (for the resident)
 * This is recursive, so an internal section will have all its children hidden.
 *
 * A `after_create` can have `internal` children too
 */
export type ApplicationElementVisibility = z.infer<
	typeof applicationElementVisibilitySchema
>;

/**
 * Gets the visibility to apply given the one from the hierarchy
 *
 * @param parent visibility from the hierarchy
 * @param current of the current element
 * @returns the visibility to apply
 */
export function getElementVisibilityToApply(
	parent: ApplicationElementVisibility,
	current: ApplicationElementVisibility,
): ApplicationElementVisibility {
	switch (parent) {
		case "public":
			return current;
		case "after_create":
			return current === "public" ? parent : current;
		case "internal":
			return "internal";
	}
}
