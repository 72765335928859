import { useMutation } from "@tanstack/react-query";
import { Formik, useFormikContext } from "formik";
import { useParams } from "next/navigation";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { COMMUNE_NO_GROUP_ID } from "~/common/organization/group";

import { LayoutModal, ModalProps } from "./LayoutModal";
import { organizationUserRepository } from "../../../../services/api/repositories/organizationUserRepository";
import { FetchError } from "../../../../services/api/utils/FetchError";
import { Icon, IconName } from "../../../ui-atoms/components/Icons/Icon";
import { ToastVariants } from "../../../ui-atoms/components/Toast";
import { showToast } from "../../../ui-atoms/services/showToast";
import {
	Button,
	ButtonVariants,
} from "../../../ui-form/components/Button/Button";
import {
	EditUserForm,
	editUserFormValuesSchema,
} from "../../../user/components/EditUserForm";

/** property detail of current user */
export interface UserProps {
	email?: string;
	enabled?: boolean;
	firstName?: string;
	groupId?: number;
	id: number;
	isAdmin?: boolean;
	lastName?: string;
	password?: string;
}

/** Different state or function for modal */
export type StateProps = Pick<ModalProps, "onClose" | "open"> & {
	disabled: boolean;
	refreshUserList: () => void;
};

/** Props for handle state and user properties */
export interface AccountFormProps {
	state: StateProps;
	user: UserProps;
}

export const AccountFormModal = ({ state, user }: AccountFormProps) => {
	const { t } = useTranslation();
	const { organizationId } = useParams<{ organizationId: string }>();

	const [isOpen, setIsOpen] = useState(true);

	type FormValues =
		| {
				data: {
					groupId: number;
					isAdmin: boolean;
					name: {
						first: string;
						last: string;
					};
				};
				type: "regular";
		  }
		| {
				enable: boolean;
				type: "de-enable";
		  };

	const initialValues: FormValues = {
		data: {
			groupId: user.groupId || COMMUNE_NO_GROUP_ID,
			isAdmin: user.isAdmin || false,
			name: {
				first: user.firstName || "",
				last: user.lastName || "",
			},
		},
		type: "regular",
	};

	const { mutateAsync } = useMutation({
		...organizationUserRepository.updateUserStatus(
			user.id,
			Number(organizationId),
		),
		meta: {
			onError: ({ response }: FetchError) => {
				if (response.status === 400) {
					showToast({
						message: t("pages.users.add-user.errors.bad_request"),
						title: "Error",
						variant: ToastVariants.ERROR,
					});
				}
			},
			onSuccess: () => {
				showToast({
					message: t("pages.users.edit-user.success"),
					title: t("common.api.success.generic-title"),
					variant: ToastVariants.SUCCESS,
				});
				state.refreshUserList();
			},
		},
	});

	const { mutateAsync: mutateAsyncUserStatus } = useMutation({
		...organizationUserRepository.updateUserStatus(
			user.id,
			Number(organizationId),
		),
		meta: {
			onSuccess: () => {
				state.refreshUserList();
			},
		},
	});

	const onSubmitHandler = async (values: FormValues) => {
		await mutateAsync(values);
		onCloseModal();
	};

	const onSubmitUserStatusHandler = async (status: boolean) => {
		await mutateAsyncUserStatus({ enable: status, type: "de-enable" });
		onCloseModal();
	};

	useEffect(() => {
		setIsOpen(state.open || true);
	}, [state.open]);

	const Actions = () => {
		const { handleSubmit, isSubmitting } = useFormikContext<FormValues>();

		return (
			<>
				<Button
					label={t("common.button.cancel")}
					onClick={() => onCloseModal()}
					variant={ButtonVariants.SECONDARY}
				/>
				<Button
					isLoading={isSubmitting}
					label={t("common.button.validate")}
					onClick={handleSubmit}
					startDecorator={
						<Icon name={IconName.checkmark} size={20} />
					}
				/>
			</>
		);
	};

	const body = (
		<EditUserForm
			organizationId={Number(organizationId)}
			setUserStatus={onSubmitUserStatusHandler}
			user={user}
		/>
	);

	const onCloseModal = () => {
		setIsOpen(false);
		state.onClose?.();
	};
	return (
		<Formik<FormValues>
			initialValues={initialValues}
			onSubmit={onSubmitHandler}
			validationSchema={toFormikValidationSchema(validationSchema)}
		>
			{() => (
				<LayoutModal
					actions={Actions}
					body={body}
					onClose={onCloseModal}
					open={isOpen}
					title={t("pages.user-edit.title")}
				/>
			)}
		</Formik>
	);
};

const validationSchema = editUserFormValuesSchema;
