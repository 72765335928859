import { Model } from "@appti/core";
import * as z from "zod";

import { schema } from "./content";
import { applicationElementVisibilitySchema } from "./element.visibility";

export const layoutSchema = z.enum(["FULL", "HALF"]);
export type LayoutModel = z.infer<typeof layoutSchema>;

/** Validation schema for {@link ApplicationElementModel} */
export const applicationElementModelSchema = Model.schema.extend({
	// Note: this schema (for content) is not the top-level,
	//	so the root element is uniform between other data structure (ZodObject, "regular" DB entity, ...)
	content: z.lazy(() => schema),
	visibility: applicationElementVisibilitySchema,

	// TODO: Move this in content?
	layout: layoutSchema,
});
/**
 * An element in the application (template) is a chunk of data for visual representation.
 *
 * It can be compared to a `<div>{element content}</div>` in HTML,
 * 	and creating a tree with its content.
 */
export type ApplicationElementModel = z.infer<
	typeof applicationElementModelSchema
>;
