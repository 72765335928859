import { Input as InputJoy, inputClasses, styled } from "@mui/joy";
import { ChangeEvent, CSSProperties, ReactNode, useState } from "react";

import { Button, ButtonVariants } from "./Button/Button";
import { UI_INPUT_CSS_VARS } from "./style";
import { Icon, IconName } from "../../ui-atoms/components/Icons/Icon";
import { textLabelTag, textSmall } from "../../ui-layout/styles/textStyles";
import { theme } from "../../ui-layout/styles/theme";

/** Variants for input styles */
export enum InputVariants {
	DEFAULT = "DEFAULT",
	PROVISION = "PROVISION",
	PROVISION_MOBILE = "PROVISION_MOBILE",
}

/**
 * name: The name of the input
 * disabled: Whether the input is disabled
 * endDecorator: The decorator to display at the end of the input
 * error: Whether the input has an error
 * onChange: The function to call when the input value changes
 * placeholder: The placeholder text to display in the input
 * showPasswordToggle: Whether to display the password toggle
 * startDecorator: The decorator to display at the start of the input
 * type: The type of the input
 * value: The value to display in the input
 * variant: The variant of the input
 * style: The style of the input
 * max: The maximum value for the input
 * min: The minimum value for the input
 */
export interface InputProps {
	disabled?: boolean;
	endDecorator?: ReactNode;
	error?: boolean;
	max?: number;
	min?: number;
	name?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	showPasswordToggle?: boolean;
	startDecorator?: ReactNode;
	style?: CSSProperties;
	type?: "date" | "email" | "number" | "password" | "text";
	value?: string;
	variant?: InputVariants;
}

export const Input = ({
	name,
	showPasswordToggle,
	type = "text",
	variant = InputVariants.DEFAULT,
	...rest
}: InputProps): JSX.Element => {
	const [showPassword, setShowPassword] = useState(false);

	const ButtonPassword = () => (
		<Button
			onClick={() => setShowPassword(!showPassword)}
			startDecorator={
				showPassword ? (
					<Icon name={IconName.viewOff} />
				) : (
					<Icon name={IconName.view} />
				)
			}
			type="button"
			variant={ButtonVariants.TEXT}
		/>
	);

	let typeComputed = type;
	if (showPasswordToggle) {
		typeComputed = showPassword ? "text" : "password";
	}

	return (
		<InputStyled
			endDecorator={showPasswordToggle && <ButtonPassword />}
			id={name}
			localvariant={variant}
			name={name}
			type={typeComputed}
			{...rest}
		/>
	);
};

type StyleDef = {
	[variant in InputVariants]: {
		backgroundColor: string;
		borderRadius: string;
		height: string;
		padding: string;
	};
};

export const styles: StyleDef = {
	[InputVariants.DEFAULT]: {
		backgroundColor: theme.palette.grey[1],
		borderRadius: "4px",
		height: "32px",
		padding: "4px 12px",
	},
	[InputVariants.PROVISION]: {
		backgroundColor: theme.palette.white.white,
		borderRadius: "2px",
		height: "24px",
		padding: "4px 8px",
	},
	[InputVariants.PROVISION_MOBILE]: {
		backgroundColor: theme.palette.white.white,
		borderRadius: "2px",
		height: "32px",
		padding: "4px 12px",
	},
};

const InputStyled = styled(InputJoy)<{
	error?: boolean;
	localvariant: InputVariants;
}>`
	border-radius: ${({ localvariant }) => styles[localvariant].borderRadius};
	background-color: var(
		${UI_INPUT_CSS_VARS.bgColor},
		${({ localvariant }) => styles[localvariant].backgroundColor}
	);
	border: 1px solid
		${({ error, theme }) =>
			error ? theme.vars.palette.red[1] : theme.vars.palette.grey[2]};
	height: ${({ localvariant }) => styles[localvariant].height};
	min-height: ${({ localvariant }) => styles[localvariant].height};
	padding: ${({ localvariant }) => styles[localvariant].padding};
	color: ${({ theme }) => theme.vars.palette.grey.text};
	${textSmall};
	box-shadow: none;
	--Input-focusedThickness: 0;

	&.${inputClasses.disabled} {
		background: ${({ theme }) => theme.vars.palette.grey[2]};
		color: ${({ theme }) => theme.vars.palette.grey[4]};
		cursor: not-allowed !important;
		pointer-events: all !important;
	}

	&.${inputClasses.disabled} .${inputClasses.input} {
		cursor: not-allowed !important;
		pointer-events: all !important;
	}

	& .${inputClasses.endDecorator} {
		${textLabelTag};
	}

	/* DISABLED ARROWS ON NUMBER INPUT */
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
`;
