import * as z from "zod";

import { userModelSchema } from "../user.model";

/** Validation schema for {@link UserUpdateDto} */
export const userUpdateDtoSchema = userModelSchema
	.pick({ name: true })
	.extend({
		groupId: z
			.number()
			.nullable()
			.describe("Group to set the user at creation"),
		isAdmin: z.boolean().describe("Is the user an admin"),
	})
	.partial()
	.strict();

/** Dto to update an user */
export type UserUpdateDto = z.infer<typeof userUpdateDtoSchema>;
